window.googletag = window.googletag || {};
googletag.cmd = googletag.cmd || [];
googletag.cmd.push(function () {
  googletag.defineSlot('/94446337/1201_usminkabu_pc_primerectangle', [[300, 250], [300, 600]], 'div-gpt-ad-1660806547733-0').addService(googletag.pubads());
  googletag.defineSlot('/94446337/1203_usminkabu_pc_middlerectangle', [[300, 250], [300, 600]], 'div-gpt-ad-1660806634034-0').addService(googletag.pubads());
  googletag.defineSlot('/94446337/8100_usminkabu_pc_premiumbanner', [[1,1], [970,90]], 'div-gpt-ad-1660806885541-0').addService(googletag.pubads());
  googletag.defineSlot('/94446337/1291_usminkabu_pc_gatead_left', [[160, 600], [174, 1024]], 'div-gpt-ad-1660806714252-0').addService(googletag.pubads());
  googletag.defineSlot('/94446337/1291_usminkabu_pc_gatead_right', [[160, 600],[174, 1024]],  'div-gpt-ad-1660806769821-0').addService(googletag.pubads());
  googletag.defineSlot('/94446337/1561_usminkabu_pc_longbanner', [650, 300], 'div-gpt-ad-1660806828624-0').addService(googletag.pubads());
  googletag.pubads().enableSingleRequest();
  googletag.enableServices();
});

googletag.cmd.push(function () {
  googletag.cmd.push(function() { googletag.display('div-gpt-ad-1660806547733-0'); }); // 1201
  googletag.cmd.push(function() { googletag.display('div-gpt-ad-1660806634034-0'); }); // 1203
  googletag.cmd.push(function() { googletag.display('div-gpt-ad-1660806714252-0'); }); // 1291 left
  googletag.cmd.push(function() { googletag.display('div-gpt-ad-1660806769821-0'); }); // 1291 right
  googletag.cmd.push(function() { googletag.display('div-gpt-ad-1660806828624-0'); }); // 1561
  googletag.cmd.push(function() { googletag.display('div-gpt-ad-1660806885541-0'); }); // 8100
});
